import React from 'react';
import ReactDOM from 'react-dom';
import '@synergycms_core/less/fonts.css';

import { Provider } from 'react-redux';


import App from './App';
import * as serviceWorker from './serviceWorker';
import ThemeProvider from '@synergycms_core/components/providers/themeProvider';
import ContentProvider from '@synergycms_core/components/providers/translateProvider';

import { defaults } from "@synergycms_core/utils";
import PopoverProvider from '@synergycms_core/components/providers/popoverProvider.jsx';
import moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/ca';
import 'moment/locale/en-ie';
import 'moment/locale/it';
import 'moment/locale/de';
import 'moment/locale/pt';
import 'moment/locale/ru';
import 'moment/locale/nl';
import 'moment/locale/fr';
import 'moment/locale/tr';
import 'moment/locale/zh-cn';
import 'moment/locale/gl';
import 'moment/locale/ar';
import 'moment/locale/hu';
import 'moment/locale/cs';
import 'moment/locale/vi';
import 'moment/locale/sk';
import 'moment/locale/ro';
import 'moment/locale/pl';
import 'moment/locale/ja';
import { langCodeCorrector, momentLangCodes } from '@synergycms_core/utils/api';
import { toLower, is } from 'ramda';
import store from './configureStore.js';
import initializeStore from './start';

//Parametres basics

window.engineVersion = process.env.APP_VERSION;
console.log(window.engineVersion);

let data = null;

const setDefaults = (engineSygyData) => {
  let result = null;
  if (engineSygyData && engineSygyData.outer_web === false) {

    const hotelCode = (engineSygyData && engineSygyData.hotel_code) || window.hotel_code || window.id_hotel;
    const hotelsInfo = (engineSygyData && engineSygyData.hotels_info) || window.hotels_info;
    let webType = null;

    if (hotelCode) {

      webType = "WI";
    } else if (hotelsInfo) {
      webType = "PORTAL"
    }
    if (engineSygyData.lang) {
      engineSygyData.lang = langCodeCorrector(engineSygyData.lang);
    }

    result = defaults(engineSygyData || {}, {
      outer_web: false,
      element_id: "default_sygy_element_engine",
      url: window.avail_url,
      lang: langCodeCorrector(window.lang_info ? window.lang_info.code : "es"),
      occups: { 0: { adults: 2, childs: 0 } },
      limits: window.occup_limits,
      hotel_code: hotelCode,
      hotels_info: hotelsInfo,
      web_type: webType,
      show_modal_view: window.show_modal_view || false,
      drop_down_list_type: null,
      focus_on_modal_view: true,
      country_id: window.country_id || null,
      city_id: window.city_id || null,
      start: null,
      end: null,
      theme_values: null,
      engine_visible: true,
      theme: window.synergyCmsAvailTheme || null,
      links: {},
      i18n: window.i18n,
      engine_sygy_data: engineSygyData,
      corporate_login: window.LoginUser && +window.LoginUser.tipo_perfil === 1 ? window.LoginUser : null,
      star_traveler_login: window.LoginUser && +window.LoginUser.tipo_perfil === 2 ? window.LoginUser : null,
    });
  } else {
    const hotelCode = engineSygyData && engineSygyData.hotel_code;
    const hotelsInfo = engineSygyData && engineSygyData.hotels_info;
    let webType = null;
    if (hotelCode) {
      webType = "WI";
    } else if (hotelsInfo) {
      webType = "PORTAL"
    }

    result = defaults(engineSygyData || {}, {
      outer_web: true,  //si es web externa, per defecte es configura com a motor extern 
      element_id: "default_sygy_element_engine", //element html on es carregara el motor
      url: null, // url per llançar les peticions de disponiblitat es pot estructurar per idioma {es:url,en:url} o passar string si es unica
      match: null, // config preseleccionat per defecte només portal
      lang: "es", //idioma actual en q es mostra el motor
      occups: { 0: { adults: 2, childs: 0 } }, // configuració preseleccionada default d'ocupaccions al carregar el motor
      limits: null, //limits de configuracio per les ocupaccions y al agregar nova habitació : { rooms = { max: 10, min:1 }, adults = { max:8, min:1, default: 2 }, childs = { igual q adult } }
      hotel_code: hotelCode, // motor mono hotel WI, no mostra el buscador
      hotels_info: hotelsInfo, // motor multi hotel PORTAL, mostra el buscador amb les opcions configurades y el mateix format q hotels_info
      web_type: webType, // parametre preconfigurat per indicar si es portal o WI
      show_modal_view: false, // en versio mobil desplega automaticament el modal del MOTOR al carregar
      drop_down_list_type: null, //
      focus_on_modal_view: true, // establecer el foco en el input de busqueda al desplegar el motor
      start: null, //data inici per defecte sino agafa dia actual
      end: null, //data fi per defecte sino agafa 1 dia més q start
      theme_values: null, // joc de colors utilitzats per pintar avail
      theme: null, // utilitzat per mostrar agrupacions de informacio o imatges estaticas s'utilitza amb withContent
      links: {},//
      engine_visible: true,//esconde o muestra el motor en vista mobil
      engine_sygy_data: engineSygyData // el mismo objecto q recibe el motor, sin perder el puntero en memoria, proporciana funciones al exterior para controlar el motor
    });
  }

  return result;
}

if (window.engine_sygy_data) {
  if (is(Array, window.engine_sygy_data)) {
    data = window.engine_sygy_data.map((config) => {
      return setDefaults(config);
    });
  } else {
    data = setDefaults(window.engine_sygy_data);
  }
} else {
  data = setDefaults({ outer_web: false });
  if (!data.hotel_code && !data.hotels_info) {
    data = {
      theme_values: {
        mainColor: "#c94",
        secondColor: '#5ca70a',
        backgroundColor: '#00111b',
        backgroundEngine : '#00111b',
        calendarColor: '#c94',
        colorFont: '#00111B',
        engineColorFont:'#c94',
        headerColorFont:'#c94',
        headerColorIcons:'#c94',
        fontFamily1: 'Roboto , sans-serif',
        fontFamily2: 'DroidSerif',
        fontFamily3: 'Icons',
        defaultColor: '#000'
      },
      outer_web: true,
      hotel_code: "SYN1275",
      url: "https://hoteldemo.booking-channel.com/disponibilidad.html",
      lang: "es",
      occups: { 0: { adults: 2, childs: 0 } },
      links: {},
      auto_open: true,
      element_id: "default_sygy_element_engine",
      web_type: "WI"
    }
  }
}

//establim l'idioma
const lang = toLower(momentLangCodes(data.lang || "es"));
moment.locale(lang);


//Agreguem estils i element per defecte al body
if (process.env.NODE_ENV !== 'development') {
  /*const styleUrl = "https://synergy2.booking-channel.com/igm/synergycms_searchengine/build/static/css/main.css";
  if (document.createStyleSheet) {
    document.createStyleSheet(styleUrl);
  } else {
    var styles = "@import url('" + styleUrl + "');";
    var newSS = document.createElement('link');
    newSS.rel = 'stylesheet';
    newSS.href = 'data:text/css,' + escape(styles);
    document.getElementsByTagName("head")[0].prepend(newSS);
  }
  if (!is(Array, data) && !document.getElementById(data.element_id)) {
    var newDiv = document.createElement("div");
    newDiv.setAttribute("id", data.element_id);
    document.body.prepend(newDiv);
  }*/
}
/*
window.engine_sygy_data = {
      outer_web: true,
      //hotels_info: hotels,
      hotel_code: "E092",
      url: "https://www.eurostarshotels.com/disponibilidad.html",
      lang: "es",
      //city_id: "25287",
      auto_open: true
    };
var engine = document.createElement('script');
engine.setAttribute('src', "https://synergy2.booking-channel.com/igm/synergycms_searchengine/build/static/js/main.js?nomemcache");
document.head.appendChild(engine);
*/
initializeStore(store.dispatch, store.getState, data);

const generateReactDoom = (config) => {
  ReactDOM.render(
    <Provider store={store}>
      <ContentProvider value={{
        lang: config.lang,
        theme: config.theme,
        i18n: config.i18n,
        links: config.links
      }}>
        <ThemeProvider value={{
          appId: config.element_id,
          theme: config.theme,
          themeValues: config.theme_values
        }}>
          <PopoverProvider>
            {config && config.web_type && config.url ?
              <App data={config} /> :
              <div style={{ color: "red" }}>Error: load engine required params : url || hotels_info || hotel_code</div>}
          </PopoverProvider>
        </ThemeProvider>
      </ContentProvider>
    </Provider>, document.getElementById(config.element_id));
}

if (is(Array, data)) {
  data.forEach((config) => {
    generateReactDoom(config);
  });
} else {
  generateReactDoom(data);
}



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();


