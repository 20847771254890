import actions from './store/actions';
import moment from 'moment';
import { Map } from 'immutable';
import {find} from 'ramda';
import { contextualizeAppConfig } from "@synergycms_core/store/app/utils";
const {
    hotels: hotelsActions,
    group: groupActions,
    app: appActions,
    customer : customerActions
} = actions;

const {
  webType: webTypeActions,
  params: paramsActions,
  langInfo: langActions,
  currencies: currenciesActions,
  currentCurrency : currentCurrencyActions,
  currentHotel: currentHotelActions
} = customerActions;


const initializeStore = (dispatch, getState, data) => {
  //dispatch(langActions.get(window.lang_info));
  

    
    dispatch(appActions.name.get("searchEngine"));
    dispatch(langActions.get(window.engine_sygy_data.lang_info || { code : window.engine_sygy_data.lang}));
    dispatch(webTypeActions.get(window.web_type));
    dispatch(appActions.outerWeb.get(window.engine_sygy_data.outer_web));
    
    
    
    let  windowConfig = window.engine_sygy_data;
    let { hotel_id, group, hotels_info, currencies, currency_code, hotel_code, config } = windowConfig;
    const hotelCode = hotel_code || window.hotel_code;
    let hotId = hotel_id || window.id_hotel;
    let baseDataRequest = Promise.resolve(hotId);
    
    
    
    if (group === "eurostarshotelsficha") {
      group = "eurostarshotels";
    }
    group && dispatch(groupActions.get(group));
    

    if (!hotels_info && (hotId || hotelCode)) {
      let hotelRequest = Promise.resolve(hotId);
      if(!hotId) {
        hotelRequest = dispatch(hotelsActions.info.getAsync(hotelCode)).then(listId => {
          return listId[hotelCode];
        }).catch((err)=> {
          console.log(err);
        });
      }
      baseDataRequest = hotelRequest.then((resultId) => {
          hotId = resultId;
          dispatch(currentHotelActions.set(hotId));
          return dispatch(actions.app.config.getHotelAsync("cms-engine", hotId, config));
        }).catch((err)=> {
          console.log(err);
        });
    } else if(group) {
      baseDataRequest = dispatch(actions.app.config.getAsync("cms-engine", config)).then(()=>{
        dispatch(hotelsActions.info.get(hotels_info));
      });
    }
    
    baseDataRequest.then(()=>{
      const engineConfigs = contextualizeAppConfig(getState(), hotId, "engine_config");
      
      if (engineConfigs.get("prices_active")) {
        const destination = !hotId && getDefaultDestination(hotels_info, windowConfig);
        const requiredAction = dispatch(currencies && currencies.length ?  currenciesActions.get(currencies) : currenciesActions.getAsync());
        if(destination) {
          destination && dispatch(paramsActions.availCacheParams(Map({
            city_id: destination.isCity && destination.id,
            country_id: destination.isCountry && destination.id,
            start: moment().format('YYYY-MM-DD')
          })));
        } else if(hotId) {
          dispatch(paramsActions.availCacheParams(Map({
            hotelId: hotId,
            start: moment().format('YYYY-MM-DD')
          })));
        }


        return Promise.resolve(requiredAction).then(() => {
          dispatch(currentCurrencyActions.set(engineConfigs.get("currency_code") || currency_code, destination));
          if(getState().getIn(["customer", "current_currency"])) {
              return dispatch(actions.availCache.getAsync());
          }
        });
      }
    }).catch((err)=>{
        console.log(err);
    });
};

var getDefaultDestination = (hotelsInfo, params)=>{
  let result;

  if (!result && (params.city_id || params.cityId)) {
    const cityId = params.city_id || params.cityId;
    result = find(city => city.id.toString() === cityId.toString(), hotelsInfo.cities);
    if(result) {
      result.city_id = result.id;
     result.isCity = true;
    }
  }

  if (!result && (params.country_id || params.countryId)) {
    const countryId = params.country_id || params.countryId;
    result = find(country => country.id.toString() === countryId.toString(),  hotelsInfo.countries);
    if(result) {
      result.country_id = result.id;
      result.isCountry = true;
    }
  }

  return result;
};

export default initializeStore;